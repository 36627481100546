
















import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import PasWordiInput from './pasword_input.vue'
import { message } from 'ant-design-vue'
import { SiteStateModule } from '@/store/site_state'
@Component({
  name: 'passwprd',
  components: {
    PasWordiInput
  }
})
export default class UserAccount extends Vue {
  @Prop() avatar?: any
  private ShowPassword = false
  private confirmLoading = false
  modifyPwd() {
    this.ShowPassword = true
    console.log('modifyPwd')
  }
  @Ref() cpi!: PasWordiInput
  async handleSave() {
    if (this.cpi && this.cpi.save) {
      try {
        const datas: any = await this.cpi.save()
        this.confirmLoading = true
        let url = '/api/hyper_fast/sys/user/resetPass'
        let params: any = datas
        const { data } = await this.$http.post(url, params)
        if (data.code === 0) {
          message.success('success')
          SiteStateModule.logout()
        }
        this.confirmLoading = false
      } catch (error: any) {
        this.confirmLoading = false
      }
    }
  }
  handleCancel() {
    this.ShowPassword = false
    this.confirmLoading = false
  }
}
